import React, { useState, useRef, useEffect } from "react";
import "../../BookingDetails.css";
import Top_navbar from "../../../DashBaord/Top_navbar";
import { CheckOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { IoCloseCircleOutline, IoArrowBack } from "react-icons/io5";
import { FaRegCircleCheck } from "react-icons/fa6";
import { notification } from "antd";
import BookingFilterDrawer from "../ReScheduleFilter";

import moment from "moment";
import { ASSETS_BASE_URL } from "../../../../config/constants";
import {
  chnageStatus,
  cancelBooking,
} from "../../../../controllers/bookings/veterinaryControllers";
import { Link, useLocation, useNavigate } from "react-router-dom";

import BookingConformatuinPopUp from "../BookingConformatuinPopUp";
import BookingDeclineConformationPopup from "../BookingDeclineConformationPopup";
import {calculateAge} from "../../../../controllers/common";
const BookingDetails = () => {
  // re-schedule Drawer
  const  targetRef = useRef();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const RequesttoggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [bookingData, setBookingData] = useState(state);

  const renderOrderButton = (
    currentStatus,
    newStatus,
    buttonText,
    popupMessage
  ) => {
    return (
      bookingData?.status === currentStatus && (
        <button
          type="button"
          className="order_now_heading_button1"
          onClick={() => {
            setPopupMessage(popupMessage);
            setOrderStatus(newStatus);
            togglePopUp();
          }}
        >
          <FaRegCircleCheck /> {buttonText}
        </button>
      )
    );
  };

  const bookingResStatus = (status) => {
    try{
        if(status === 'P'){
            return `Pending`;
        } else if( status === 'A'){
            return `Accepted`;
        } else if(status === 'D'){
            return `Declined`;
        } else {
            return `${status}`;
        }
    } catch(error) {
        // console.log(error) 
        return '';
    }
  };

  const getUserType = (type) => {
    try{
        if(type === 'U'){
            return `User`;
        } else if( type === 'S'){
            return `Store`;
        } else if(type === 'B'){
            return `Branch`;
        } else {
            return `${type}`;
        }
    } catch(error) {
        // console.log(error) 
        return '';
    }
  };

  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [declinePopUpOpen, setdeclinePopUpOpen] = useState(false);
  const toggleDeclinePopUp = () => {
    setdeclinePopUpOpen(!declinePopUpOpen);
  };

  const [order_status, setOrderStatus] = useState("");
  const [popupMessage, setPopupMessage] = useState("");

  const handleOrderStatusChange = async (status = "") => {
    try {
      let statusError = false;
      let bookingStatus = bookingData.status;
      if (status === "Accept" && bookingData.status === "Pending") {
        bookingStatus = "Accept";
      } else if (status === "Completed" && bookingData.status === "Accept") {
        bookingStatus = "Completed";
      } else {
        statusError = true;
      }
      if (statusError === true) {
        notification.open({
          message: `Oops!`,
          description: " Your order status not changed .",
          placement: "topRight",
          icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      } else {
        const options = {
          bookingId: bookingData._id,
          status: bookingStatus,
        };
        const res = await chnageStatus(options);
        if (res.status) {
          notification.open({
            message: `Booking status changed`,
            description: " Your booking status changed.",
            placement: "topRight",
            icon: <CheckOutlined style={{ color: "green" }} />,
            duration: 2,
          });
          navigate("/bookings/veterinary/list");
        } else {
          notification.open({
            message: `Oops!`,
            description: " Your booking status not changed.",
            placement: "topRight",
            icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      }
    } catch (error) {
      notification.open({
        message: `Oops!`,
        description: "Server is under maintenance.",
        placement: "topRight",
        icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
  };

  const handleOrderDecline = async (reason = "") => {
    try {
      let statusError = false;
      let bookingStatus = bookingData.status;
      if (bookingData.status !== "Complete") {
        bookingStatus = "Canceled";
      } else {
        statusError = true;
      }
      if (statusError === true) {
        notification.open({
          message: `Oops!`,
          description: " Your booking status not changed .",
          placement: "topRight",
          icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      } else {
        const options = {
          bookingId: bookingData._id,
          reason: reason,
        };
        const res = await cancelBooking(options);
        if (res.status) {
          notification.open({
            message: `Booking Decline!`,
            description: "Booking Decline Successfully .",
            placement: "topRight",
            icon: <CheckOutlined style={{ color: "green" }} />,
            duration: 2,
          });
          navigate("/bookings/veterinary/list");
        } else {
          notification.open({
            message: `Oops!`,
            description: "Your booking not decline .",
            placement: "topRight",
            icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      }
    } catch (error) {
      notification.open({
        message: `Oops!`,
        description: "Server is under maintenance.",
        placement: "topRight",
        icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
  };
  useEffect(()=>{
    targetRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  },[]);
  return (
    <div ref={targetRef}>
      <div className="container-fluid">
        <div>
          <div className="orders_main_contaniner">
            <div>
              {/* <Top_navbar title={`Booking Details`}></Top_navbar> */}
              <nav class="navbar navbar-light  justify-content-between w-100 navbar_content">
                <Link
                    to="/bookings/veterinary/list"
                    class="navbar-brand common_inventory_heading"
                    >
                    <IoArrowBack className="orders_now_IoArrow" /> Veterinary booking details{" "}
                  </Link>
              </nav>
            </div>
            <div className="order_now_card_tilte">
              <div>
                <div class="card-body order_now_date_and_time_section">
                  <div>
                    <span className="orders_now_billing_details_heading">
                      Booking Id : {bookingData?.booking_seq_id}
                    </span>
                    <address className="order_now_address_date_and_time">
                      <span>
                        {" "}
                        {moment(`${bookingData?.createdAt}`).format(
                          "MMM DD, YYYY, hh:mm A"
                        )}{" "}
                      </span>
                    </address>
                  </div>
                  {bookingData?.status !== "Canceled" &&
                    bookingData?.status !== "Completed" &&
                    bookingData?.status !== "Hold" && (
                      <div className="fa_filter_rating_review">
                        <div>
                          <span
                            className="order_now_vendor_re_schedule"
                            onClick={RequesttoggleDrawer}
                          >
                            Re-schedule
                          </span>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8">
                {bookingData?.bookingdetails?.length > 0 &&
                  bookingData?.bookingdetails.map((item) => (
                    <>
                      <div className="card mb-4">
                        <div className="card-body">
                          <div class="mb-3 d-flex justify-content-between">
                            <div>
                              <span className="booking_details_billing_details_heading">
                                Services
                              </span>
                            </div>
                          </div>
                          <table class="table table-borderless booking_deatils_table_for_tr orders_now_table">
                            <tbody>
                              
                              <tr>
                                <td colspan="2" className="orders_now_subtotal">
                                  Service Name
                                </td>
                                <td class="text-end order_now_rupees">
                                  {" "}
                                  {item?.services?.name}
                                </td>
                              </tr>
                              {bookingData?.servive_type && (
                                <tr>
                                  <td
                                    colspan="2"
                                    className="orders_now_subtotal"
                                  >
                                    Service Type
                                  </td>
                                  <td class="text-end order_now_rupees">
                                    {" "}
                                    {bookingData?.servive_type}
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <td colspan="2" className="orders_now_subtotal">
                                  Price
                                </td>
                                <td class="text-end order_now_rupees">
                                  {" "}
                                  AED {item?.subtotal?.toFixed(2)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {/* ... (rest of the code) */}
                        </div>
                      </div>
                    </>
                  ))}
                <div className="card mb-4 ">
                  <div className="card-body">
                    <div class="mb-3 d-flex justify-content-between">
                      <div>
                        <span className="booking_details_billing_details_heading">
                          Billing Details
                        </span>
                      </div>
                    </div>
                    <table class="table table-borderless booking_deatils_table_for_tr orders_now_table">
                      <tbody>
                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            Sub-Total
                          </td>
                          <td class="text-end order_now_rupees">
                            AED{" "}
                            {(bookingData?.total -
                              (parseFloat(bookingData?.subTotal) * 5) /
                                100).toFixed(2)}{" "}
                          </td>
                        </tr>
                        {bookingData?.discount !== 0 && (
                          <>
                            <tr>
                              <td colspan="2" className="orders_now_subtotal">
                                {" "}
                                Discount{" "}
                              </td>
                              <td class="text-end order_now_rupees">
                                - AED {bookingData?.discount?.toFixed(2)}{" "}
                              </td>
                            </tr>
                          </>
                        )}
                        {bookingData?.isTreatsUsed === 'Y' && (
                          <>
                            <tr>
                              <td colspan="2" className="orders_now_subtotal">
                                {" "}
                                Treats ({bookingData?.treats?.toFixed(2)}){" "}
                              </td>
                              <td class="text-end order_now_rupees">
                                - AED {bookingData?.treats_discount?.toFixed(2)}{" "}
                              </td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            Tax{" "}
                            <span className="booking_details_convenience">
                              ( 5% VAT )
                            </span>
                          </td>
                          <td class="text-end order_now_rupees">
                            {" "}
                            AED {((parseFloat(bookingData?.subTotal) * 5) / 100).toFixed(2)}
                          </td>
                        </tr>

                        {/* <hr /> */}
                        <tr class="fw-bold">
                          <td
                            colspan="2"
                            className="booking_details_payable_amount"
                          >
                            Payable Amount
                          </td>
                          <td class="text-end booking_details_payable_amount">
                            AED {bookingData?.paidAmount?.toFixed(2)}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* ... (rest of the code) */}
                  </div>
                </div>

                {bookingData?.petInfo && (
                  <>
                    <div className="card mb-4">
                      <div className="card-body">
                        <div class="mb-3 d-flex justify-content-between">
                          <div>
                            <span className="booking_details_billing_details_heading">
                              Pet's Details
                            </span>
                          </div>
                        </div>
                        <table class="table table-borderless booking_deatils_table_for_tr orders_now_table">
                          <tbody>
                            <tr>
                              <td colspan="2" className="orders_now_subtotal">
                                Name 
                              </td>
                              <td class="text-end order_now_rupees">
                                {bookingData?.petInfo?.name?.charAt(0).toUpperCase() + bookingData?.petInfo?.name?.slice(1).toLowerCase()}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2" className="orders_now_subtotal">
                                Gender 
                              </td>
                              <td class="text-end order_now_rupees">
                              {bookingData?.petInfo?.gender}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2" className="orders_now_subtotal">
                              Age 
                              </td>
                              <td class="text-end order_now_rupees">
                                <span dangerouslySetInnerHTML={{__html: calculateAge(bookingData?.petInfo?.age)}}></span>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2" className="orders_now_subtotal">
                                Breed 
                              </td>
                              <td class="text-end order_now_rupees">
                                {bookingData?.petInfo?.breed}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}

                {bookingData?.notes && (
                  <>
                    <div className="card mb-4">
                      <div className="card-body">
                        <div class="mb-3 d-flex justify-content-between">
                          <div>
                            <span className="booking_details_billing_details_heading">
                              Notes
                            </span>
                          </div>
                        </div>
                        <table class="table table-borderless booking_deatils_table_for_tr orders_now_table">
                          <tbody>
                            <tr>
                              <td colspan="2" className="orders_now_subtotal">
                                {bookingData?.notes}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="col-lg-4">
                <div className="card mb-4">
                  <div className="card-body">
                    <span className="orders_now_billing_details_heading">
                      Booking Request
                    </span>
                    <div className="booking_details_status_button">
                      {renderOrderButton(
                        "Pending",
                        "Accept",
                        "Accept Booking",
                        "Do you want to accept this booking?"
                      )}
                      {(bookingData.paymentStatus === 'Pending' || bookingData.paymentStatus === 'pending')?
                        renderOrderButton(
                          "Accept",
                          "Completed",
                          "Complete Booking",
                          "Do you want to complete this booking and mark as paid?"
                        ) : 
                        renderOrderButton(
                          "Accept",
                          "Completed",
                          "Complete Booking",
                          "Do you want to complete this booking?"
                        )
                      }

                      {bookingData?.status !== "Completed" &&
                        bookingData?.status !== "Canceled" && (
                          <button
                            type="button"
                            className=" order_now_heading_button2"
                            onClick={() => {
                              toggleDeclinePopUp();
                            }}
                          >
                            <IoCloseCircleOutline /> Decline Booking
                          </button>
                        )}
                      {bookingData?.status === "Completed" && (
                        <p className="text-success">Completed</p>
                      )}

                      {(bookingData?.status === "Canceled" ||
                        bookingData?.status === "Hold") && (
                        <>
                          <p className="text-danger">
                            <span>Status : {bookingData?.status}</span> <br />
                            <span>Reason : {bookingData?.reason}</span> <br />
                            <span>
                              Date & Time :{" "}
                              {moment(`${bookingData?.canceled_date}`).format(
                                "MMM DD, YYYY, hh:mm A"
                              )}
                            </span>{" "}
                            <br />
                            <span>By : {bookingData?.canceled_user_type}</span>
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                {/************ Reschedule Request model *************/}
              { bookingData?.isReschedule === 'Y' &&
                <div
                  className={`card mb-4 ${
                    bookingData?.rescheduleStatus === 'A'
                      ? 'reqdivaccepted'
                      : bookingData?.rescheduleStatus === 'P'
                      ? 'reqdivpending'
                      : bookingData?.rescheduleStatus === 'D'
                      ? 'reqdivdecline'
                      : ''
                  }`}
                >
                  <div className="card-body">
                    <span className="orders_now_billing_details_heading">
                      Reschedule Request {" "}
                    </span>
                    <address className="order_now_address">
                      <span>Requested By: {getUserType(bookingData?.rescheduleRequestedBy)}</span>
                      <span>Request Status: {bookingResStatus(bookingData?.rescheduleStatus)}</span>
                      <span>New Date: {moment(bookingData?.rescheduleDate).format("DD-MM-YYYY")}</span>
                      <span>New Time: {bookingData?.rescheduleTime}</span>
                    </address>
                    <hr></hr>
                    <span>Request raised on : {moment(bookingData?.rescheduleResquestedOn).format("DD-MM-YYYY")}</span>
                  </div>
                </div>}
                {/*************** Reschedule Request model *************/}

                <div className="card mb-4">
                  <div className="card-body">
                    <span className="orders_now_billing_details_heading">
                      User Details
                    </span>
                    <address className="order_now_address">
                      <span>Name: {bookingData?.ownerInfo?.name}</span>
                      <span>
                        Phone No. : +971 - {bookingData?.ownerInfo?.phone}
                      </span>
                    </address>
                  </div>
                </div>
                <div className="card mb-4">
                  <div className="card-body">
                    <span className="orders_now_billing_details_heading">
                      Appointment Date & Time
                    </span>
                    {/* <address className="booking_deatils_order_now_address">
                      <span>
                        {" "}
                        Date :{" "}
                        {moment(`${bookingData?.appointment_date}`).format(
                          "MMM DD, YYYY"
                        )}{" "}
                      </span>
                      <span> Time : {`${bookingData?.appointment_time}`} </span>
                    </address> */}
                    <table className="table table-borderless orders_now_billing_details_bottom orders_now_table">
                      { bookingData?.isReschedule === 'Y' && bookingData?.rescheduleStatus === 'A' ? 
                      (
                        <tbody>
                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            New Date
                          </td>
                          <td class="text-end order_now_rupees">
                            {moment(bookingData?.rescheduleDate).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2" className="orders_now_subtotal">
                            New Time
                          </td>
                          <td className="text-end order_now_rupees">
                            {bookingData?.rescheduleTime}
                          </td>
                        </tr>
                        <hr></hr>
                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            Old Date
                          </td>
                          <td class="text-end order_now_rupees">
                            {moment(bookingData?.appointment_date).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2" className="orders_now_subtotal">
                            Old Time
                          </td>
                          <td className="text-end order_now_rupees">
                            {bookingData?.appointment_time}
                          </td>
                        </tr>
                      </tbody>
                      ) : (
                        <tbody>
                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            Date
                          </td>
                          <td class="text-end order_now_rupees">
                            {moment(bookingData?.appointment_date).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2" className="orders_now_subtotal">
                            Time
                          </td>
                          <td className="text-end order_now_rupees">
                            {bookingData?.appointment_time}
                          </td>
                        </tr>
                      </tbody>
                      ) }
                    </table>
                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <span className="orders_now_billing_details_heading">
                      Payment Details
                    </span>
                    <address className="booking_deatils_order_now_address">
                      <span>Payment Mode : {bookingData?.paidBy?bookingData.paidBy?.charAt(0).toUpperCase() + bookingData.paidBy?.slice(1).toLowerCase():"Cash"}</span>
                      <span>Payment Status : {bookingData?.paymentStatus?.charAt(0).toUpperCase() + bookingData?.paymentStatus?.slice(1).toLowerCase()}</span>
                    </address>
                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <span className="orders_now_billing_details_heading">
                      Assigned Vendor
                    </span>
                    <address className="booking_deatils_order_now_address">
                      <span> Store Details : </span>
                      <span>Name : {bookingData?.shopId?.shop_name}</span>
                      <span>Phone : {bookingData?.shopId?.phone}</span>
                      <span>Email : {bookingData?.shopId?.email}</span>
                      <br />
                    </address>
                  </div>
                </div>
              </div>

              {/* ////// */}
            </div>
          </div>
        </div>
      </div>
      <BookingConformatuinPopUp
        popUpOpen={popUpOpen}
        togglePopUp={togglePopUp}
        status={order_status}
        handleOrderChnageStatus={handleOrderStatusChange}
        message={popupMessage}
      />
      <BookingDeclineConformationPopup
        popUpOpen={declinePopUpOpen}
        togglePopUp={toggleDeclinePopUp}
        handleOrderDecline={handleOrderDecline}
      />
      <BookingFilterDrawer
        drawerOpen={drawerOpen}
        RequesttoggleDrawer={RequesttoggleDrawer}
        bookingData={bookingData}
      />
    </div>
  );
};

export default BookingDetails;
